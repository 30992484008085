// @flow
import * as React from 'react';

export const PrevIcon = ({ width, height, fill }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 28 28' fill={fill} xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M14 8.33333L8.33333 14M8.33333 14L14 19.6667M8.33333 14H19.6667M8.05 26.75H19.95C22.3302 26.75 23.5203 26.75 24.4295 26.2868C25.2291 25.8793 25.8793 25.2291 26.2868 24.4295C26.75 23.5203 26.75 22.3302 26.75 19.95V8.05C26.75 5.66978 26.75 4.47967 26.2868 3.57054C25.8793 2.77085 25.2291 2.12068 24.4295 1.71322C23.5203 1.25 22.3302 1.25 19.95 1.25H8.05C5.66978 1.25 4.47967 1.25 3.57054 1.71322C2.77085 2.12068 2.12068 2.77085 1.71322 3.57054C1.25 4.47967 1.25 5.66978 1.25 8.05V19.95C1.25 22.3302 1.25 23.5203 1.71322 24.4295C2.12068 25.2291 2.77085 25.8793 3.57054 26.2868C4.47967 26.75 5.66978 26.75 8.05 26.75Z'
        stroke='black'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
