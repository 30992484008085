// @flow
import * as React from 'react';
import { LoginCp } from '../../component/LoginCp';

export const AdminLogin = () => {
  return (
    <>
      <LoginCp title={'管理者ログイン画面'} button={'ログイン'} roleUser={'admin'} />
    </>
  );
};
