// @flow
import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import * as UserService from '../api/apiServices/UserService';
import { Skeleton } from 'antd';
import { NotificationDataNone } from './NotificationDataNone';
import { CHANGE_PASSWORD_URL, REPORT_DETAIL_URL } from '../routes/constantRouter';
import getUserName from '../helper/GetUserName';

export const ResultReportListCp = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [listData, setListData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  useEffect(() => {
    const listReportData = async () => {
      const reportData = await UserService.getListReportForUser();
      setLoading(false);
      setListData(reportData.data);
    };
    listReportData();
  }, []);
  const history = useNavigate();
  const onHandleGetDetailReport = (data) => {
    history(`${REPORT_DETAIL_URL}?reportId=${data}`);
  };
  const [nameUser] = React.useState(getUserName());
  return (
    <>
      <div className='absolute mt-10 r-2 right-[30px] font-bold cursor-pointer'>
        <Link to={CHANGE_PASSWORD_URL}>
          <u>パスワード変更</u>
        </Link>
      </div>
      <div className='w-full max-w-[400px] mx-auto mt-[67px] mb-auto rounded-lg p-9'>
        <div className='text-center font-yuGothic font-bold text-[24px] mb-9'>{nameUser}さんのマイページ</div>
        <div className=' font-bold text-[16px] font-yuGothic mb-[23px]'>結果レポート一覧</div>
        {loading ? (
          <div className='item-report gap-7 flex-col'>
            <Skeleton.Input className='!w-full' active />
            <Skeleton.Input className='!w-full' active />
            <Skeleton.Input className='!w-full' active />
            <Skeleton.Input className='!w-full' active />
            <Skeleton.Input className='!w-full' active />
          </div>
        ) : (
          <>
            {listData.length === 0 && (
              <div className='flex flex-col items-center justify-center'>
                <div>
                  <NotificationDataNone />
                </div>
                <div className='text-[#70757a] font-yuGothic'>空のデータ</div>
              </div>
            )}
            <>
              {listData?.map((item, index) => (
                <div className='item-report' key={index}>
                  <p>{item.created_at}</p>
                  <div
                    aria-hidden='true'
                    onClick={() => onHandleGetDetailReport(item.id)}
                    role='button'
                    className='border-bottom-text'
                  >
                    詳細を見る
                  </div>
                </div>
              ))}
            </>
          </>
        )}
      </div>
    </>
  );
};
