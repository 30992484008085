// @flow
import * as React from 'react';

export const Header = () => {
  return (
    <div className="w-full h-[57px] bg-[#696969] flex items-center">
      <div className="ml-[121px] font-bold text-[20px] text-white">
        Global Professional
      </div>
    </div>
  );
};
