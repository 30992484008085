/* eslint-disable jsx-a11y/media-has-caption */
// @flow
import { Button, Form, Skeleton } from 'antd';
import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import * as UserService from '../api/apiServices/UserService';
import { StyledButton } from './StyledButton';
import { ROLE_ADMIN } from '../helper/constant';
import AuthContext from '../layoutt/Authorization/AuthContextProvider';
import { useNavigate } from 'react-router';
import { LIST_REPORT_URL } from '../routes/constantRouter';

export const ReportDetailCp = () => {
  const StyledButtonBack = styled(Button)`
    :hover {
      background-color: white !important;
      color: black !important;
    }
  `;

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const idUser = urlParams.get('userId');
  const reportId = urlParams.get('reportId');
  const [dataReportDetail, setDataReportDetail] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dateCreateReport, setDateCreateReport] = useState(null);
  const { typeUSer } = React.useContext(AuthContext);
  React.useEffect(() => {
    const getDataReportDetail = async () => {
      const dataDetail = await UserService.getDataReportDetail(reportId, idUser);
      setLoading(false);
      setDataReportDetail(dataDetail?.data?.files);
      setDateCreateReport(dataDetail?.data?.created_at);
    };
    getDataReportDetail();
  }, []);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const handleDownload = async () => {
    setLoadingDownload(true);
    const download = await UserService.downLoadFilePDF(dataReportDetail[0].id);
    const url = window.URL.createObjectURL(new Blob([download]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', dataReportDetail ? dataReportDetail[0]?.name : 'デフォルト名.pdf');
    document.body.appendChild(link);
    link.click();
    setLoadingDownload(false);
  };
  const history = useNavigate();
  return (
    <Form className='w-[calc(100%-20px)] mobile:w-[calc(100%-200px)] mx-auto mt-[67px] mb-auto'>
      <div className='text-center mb-[60px] font-yuGothic font-bold text-[24px]'>{dateCreateReport}</div>
      {loading ? (
        <>
          <div className={'md:grid-cols-3 grid grid-cols-1 sm:grid-cols-2 gap-[50px]'}>
            <div className='flex flex-col drop-shadow rounded-md min-h-[300px] max-h-[450px]'>
              <Skeleton.Image className='!w-full !h-full' active />
            </div>
            <div className='flex flex-col drop-shadow rounded-md min-h-[300px] max-h-[450px]'>
              <Skeleton.Image className='!w-full !h-full' active />
            </div>
            <div className='flex flex-col drop-shadow rounded-md min-h-[300px] max-h-[450px]'>
              <Skeleton.Image className='!w-full !h-full' active />
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className={`${
              dataReportDetail && dataReportDetail?.length > 2 ? 'md:grid-cols-3' : 'md:grid-cols-2'
            } grid grid-cols-1 sm:grid-cols-2 gap-[50px]`}
          >
            <div className='flex flex-col drop-shadow rounded-md min-h-[300px] max-h-[450px]'>
              <div className='flex justify-between gap-2 text-center items-center mb-3 h-[28px] shrink-0'>
                <p className='font-bold font-yuGothic text-[16px] h-full w-[200px] overflow-hidden whitespace-nowrap text-ellipsis'>
                  アセスメントレポート
                </p>
                <StyledButton
                  onClick={handleDownload}
                  disabled={loadingDownload}
                  loading={loadingDownload}
                  className='bg-[#000000] flex items-center rounded-none !h-full font-bold text-[14px] text-white cursor-pointer'
                >
                  ダウンロード
                </StyledButton>
              </div>
              <a
                href={dataReportDetail ? dataReportDetail[0]?.file_url : null}
                className='h-[calc(100%-40px)] w-full flex-1 border-solid border'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img
                  src={dataReportDetail ? dataReportDetail[0]?.thumbnail_url : null}
                  className='h-full w-full flex-1 object-cover'
                  alt='Report Detail'
                />
              </a>
            </div>
            <div className='flex flex-col drop-shadow rounded-md min-h-[300px] max-h-[450px]'>
              <div className='font-bold font-yuGothic text-[16px] mb-3 h-[24px] shrink-0'>受験時の動画</div>
              <video
                src={dataReportDetail ? dataReportDetail[1]?.video_url : null}
                className='h-[calc(100%-40px)] w-full flex-1 object-cover'
                controls
                controlsList='nodownload'
              />
            </div>
            {dataReportDetail && dataReportDetail?.length > 2 && (
              <div className='flex flex-col drop-shadow rounded-md min-h-[300px] max-h-[450px]'>
                <div className='font-bold text-[16px] mb-3 h-[24px] shrink-0'></div>
                <video
                  src={dataReportDetail ? (dataReportDetail[2] ? dataReportDetail[2]?.video_url : '') : null}
                  className='h-[calc(100%-40px)] w-full flex-1 object-cover'
                  controls
                  controlsList='nodownload'
                />
              </div>
            )}
          </div>
          <div className='my-[90px] mx-auto max-w-[300px] mobile:max-w-[400px]'>
            <StyledButtonBack
              type='primary'
              htmlType='submit'
              className='w-full flex mx-auto my-0 items-center max-w-[330px] justify-center bg-[#ffffff] border-solid border-2 border-[#000000] rounded-none h-[55px] px-[88px] font-bold text-[14px] text-black cursor-pointer'
              onClick={() => history(typeUSer === ROLE_ADMIN ? window.history.back() : LIST_REPORT_URL)}
            >
              {typeUSer === ROLE_ADMIN ? '前に戻る' : '結果レポート一覧に戻る'}
            </StyledButtonBack>
          </div>
        </>
      )}
    </Form>
  );
};
