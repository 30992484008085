import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { publicRoutes } from './routes';
import { LayoutDefault } from './layoutt/LayoutDefault';
import { Fragment } from 'react';
import { AuthContextProvider } from './layoutt/Authorization/AuthContextProvider';

function App() {
  document.title = 'Global Professional';
  return (
    <Router>
      <section>
        <Routes>
          {publicRoutes.map((route, index) => {
            const DefaultLayout = route.layout === null ? Fragment : LayoutDefault;
            const Page = route.component;
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  <DefaultLayout>
                    <AuthContextProvider>
                      <Page />
                    </AuthContextProvider>
                  </DefaultLayout>
                }
              />
            );
          })}
        </Routes>
      </section>
    </Router>
  );
}

export default App;
