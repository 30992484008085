// @flow
import { Form } from 'antd';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { DeleteIcon } from '../Icons/DeleteIcon';
import * as UserService from '../api/apiServices/UserService';
import { useForm } from 'antd/es/form/Form';
import StyledAlert from './StyledAlert';
import { StyledButton } from './StyledButton';
import { useNavigate } from 'react-router-dom';
import { DETAIL_USER_URL } from '../routes/constantRouter';
import getUserName from '../helper/GetUserName';

export const CreateReportCp = () => {
  const [windowSize, setWindowSize] = useState(null);
  useEffect(() => {
    function handleResize() {
      setWindowSize(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const reportRef = React.useRef(null);
  const video1Ref = React.useRef(null);
  const video2Ref = React.useRef(null);
  const handleClick = (data) => {
    let refData = reportRef;
    if (data === 'video1') {
      refData = video1Ref;
    }
    if (data === 'video2') {
      refData = video2Ref;
    }
    refData.current.click();
  };
  const [selectedFile, setSelectedFile] = useState({ isFilePicked: false, selectedFileItem: null });
  const [selectedVideo1, setSelectedVideo1] = useState({ isVideo1Picked: false, selectedVideo1Item: null });
  const [selectedVideo2, setSelectedVideo2] = useState({ isVideo2Picked: false, selectedVideo2Item: null });

  const handleFileChange = (event, type) => {
    if (type === 'file') {
      setSelectedFile({ isFilePicked: true, selectedFileItem: event.target.files[0] });
      const fileObj = event.target.files && event.target.files[0];
      if (!fileObj) {
        return;
      }
    }
    if (type === 'video1') {
      setSelectedVideo1({ isVideo1Picked: true, selectedVideo1Item: event.target.files[0] });
      const fileObj = event.target.files && event.target.files[0];
      if (!fileObj) {
        return;
      }
    }
    if (type === 'video2') {
      setSelectedVideo2({ isVideo2Picked: true, selectedVideo2Item: event.target.files[0] });
      const fileObj = event.target.files && event.target.files[0];
      if (!fileObj) {
        return;
      }
    }
  };

  const resetFileInput = (type) => {
    if (type === 'file') {
      reportRef.current.value = null;
      setSelectedFile({ isFilePicked: false, selectedFileItem: null });
      return;
    }
    if (type === 'video1') {
      video1Ref.current.value = null;
      setSelectedVideo1({ isVideo1Picked: false, selectedVideo1Item: null });
      return;
    }
    if (type === 'video2') {
      video2Ref.current.value = null;
      setSelectedVideo2({ isVideo2Picked: false, selectedVideo2Item: null });
      return;
    }
    setSelectedFile({ isFilePicked: false, selectedFileItem: null });
    setSelectedVideo1({ isVideo1Picked: false, selectedVideo1Item: null });
    setSelectedVideo2({ isVideo2Picked: false, selectedVideo2Item: null });
  };
  const [message, setMessage] = React.useState({ status: null, message: '', loading: false });
  const [nameUser] = React.useState(getUserName());
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const idUser = urlParams.get('userId');
  const history = useNavigate();
  const handleUploadFile = async () => {
    setMessage({ status: null, message: '', loading: true });
    const formData = new FormData();
    formData.append('pdf_file', selectedFile?.selectedFileItem);
    formData.append('list_video[]', selectedVideo1?.selectedVideo1Item);
    formData.append('list_video[]', selectedVideo2?.selectedVideo2Item);
    const data = await UserService.CreateNewReport(formData, idUser);
    resetFileInput();
    setMessage({
      status: data.status,
      message: data.status ? 'レポートを作成しました。' : 'ファイル形式が無効です',
      loading: false,
    });
    setTimeout(() => {
      history(`${DETAIL_USER_URL}?id=${idUser}`);
    }, 1200);
  };
  const [form] = useForm();
  return (
    <div className='w-full h-full max-w-[500px] mx-auto mt-[20px] mb-auto rounded-lg p-1 mobile:p-9'>
      {typeof message.status === 'boolean' && (
        <StyledAlert type={message.status ? 'success' : 'warning'} message={message.message} />
      )}
      <div className='font-bold text-[24px] font-yuGothic mb-9'>新規レポート作成</div>
      {windowSize > 567 ? (
        <Form enctype='multipart/form-data' form={form} className='bg-[white] px-5 py-8 max-h-[400px]'>
          <div className='mb-12'>
            <span className='font-bold font-yuGothic text-[16px] mr-[18px]'>受験者名</span>{' '}
            <span className='font-[500] font-yuGothic text-[14px]'>{nameUser}</span>
          </div>
          <div className='mb-[50px] items-center flex gap-5'>
            <p className='font-bold text-[16px] mobile:w-[70px] mobile:flex-shrink-0'>レポート</p>

            <div className='flex flex-1 justify-between items-center'>
              <input
                ref={reportRef}
                style={{ display: 'none' }}
                type='file'
                accept='.pdf'
                onChange={(e) => handleFileChange(e, 'file')}
              />
              <StyledButton
                onClick={() => handleClick('report')}
                className='bg-[#000000] mobile:w-[130px] mobile:flex-shrink-0 block rounded-none h-[27px] font-bold text-[12px] text-white cursor-pointer'
              >
                ファイルを添付
              </StyledButton>
              {selectedFile.isFilePicked && (
                <div>
                  <p className='mobile:flex-shrink-0 mobile:w-[100px] truncate'>
                    {' '}
                    {selectedFile.selectedFileItem.name}
                    {selectedFile.selectedFileItem.type}
                  </p>
                </div>
              )}
              <div aria-hidden='true' role='button' onClick={() => resetFileInput('file')}>
                <DeleteIcon fill={'white'} width={25} height={25} />
              </div>
            </div>
          </div>
          <div className='mb-[50px] item-center flex gap-5'>
            <p className='font-bold text-[16px] font-yuGothic mobile:w-[70px] mobile:flex-shrink-0'>動画1</p>
            <div className='flex flex-1 justify-between items-center'>
              <input
                ref={video1Ref}
                style={{ display: 'none' }}
                type='file'
                accept='video/*'
                onChange={(e) => handleFileChange(e, 'video1')}
              />
              <StyledButton
                onClick={() => handleClick('video1')}
                className='bg-[#000000] mobile:w-[130px] mobile:flex-shrink-0 block rounded-none h-[27px] font-bold text-[12px] text-white cursor-pointer'
              >
                ファイルを添付
              </StyledButton>
              {selectedVideo1.isVideo1Picked && (
                <div>
                  <p className='mobile:flex-shrink-0 mobile:w-[100px] truncate'>
                    {' '}
                    {selectedVideo1.selectedVideo1Item.name}
                    {selectedVideo1.selectedVideo1Item.type}
                  </p>
                </div>
              )}
              <div aria-hidden='true' role='button' onClick={() => resetFileInput('video1')}>
                <DeleteIcon fill={'white'} width={25} height={25} />
              </div>
            </div>
          </div>
          <div className='mb-[50px] items-center flex gap-5'>
            <p className='font-bold text-[16px] font-yuGothic mobile:w-[70px]'>動画2</p>
            <div className='flex flex-1 justify-between items-center'>
              <input
                ref={video2Ref}
                accept='video/*'
                style={{ display: 'none' }}
                type='file'
                onChange={(e) => handleFileChange(e, 'video2')}
              />
              <StyledButton
                onClick={() => handleClick('video2')}
                className='bg-[#000000] mobile:w-[130px] mobile:flex-shrink-0 block rounded-none h-[27px] font-bold text-[12px] text-white cursor-pointer'
              >
                ファイルを添付
              </StyledButton>
              {selectedVideo2.isVideo2Picked && (
                <div>
                  <p className='mobile:flex-shrink-0 mobile:w-[100px] truncate'>
                    {' '}
                    {selectedVideo2.selectedVideo2Item.name}
                    {selectedVideo2.selectedVideo2Item.type}
                  </p>
                </div>
              )}
              <div aria-hidden='true' role='button' onClick={() => resetFileInput('video2')}>
                <DeleteIcon fill={'white'} width={25} height={25} />
              </div>
            </div>
          </div>
        </Form>
      ) : (
        <Form className='bg-[white] px-5 py-8 max-h-[400px]'>
          <div className='mb-8'>
            <span className='font-bold text-[16px] font-yuGothic mr-[18px]'>受験者名</span>
            <span className='font-[500] text-[14px] font-yuGothic'>{nameUser}</span>
          </div>
          <div>
            <p className='font-bold text-[16px] mobile:w-[70px] font-yuGothic mobile:flex-shrink-0'>レポート</p>
            <div className='flex gap-3 my-3'>
              <input
                ref={reportRef}
                style={{ display: 'none' }}
                type='file'
                accept='.pdf'
                onChange={(e) => handleFileChange(e, 'file')}
              />
              <StyledButton
                onClick={() => handleClick('report')}
                className='bg-[#000000] mobile:w-[130px] mobile:flex-shrink-0 block mx-auto rounded-none h-[27px] font-bold text-[12px] text-white cursor-pointer'
              >
                ファイルを添付
              </StyledButton>
              {selectedFile.isFilePicked && (
                <div>
                  <p className='mobile:flex-shrink-0 max-w-[100px] truncate'>
                    {' '}
                    {selectedFile.selectedFileItem.name}
                    {selectedFile.selectedFileItem.type}
                  </p>
                </div>
              )}
              <div
                aria-hidden='true'
                className='flex-1 flex justify-end'
                role='button'
                onClick={() => resetFileInput('file')}
              >
                <DeleteIcon fill={'white'} width={25} height={25} />
              </div>
            </div>
          </div>
          <div>
            <p className='font-bold text-[16px] font-yuGothic mobile:w-[70px] mobile:flex-shrink-0'>動画1</p>
            <div className='flex gap-3 my-3'>
              <input
                ref={video1Ref}
                style={{ display: 'none' }}
                type='file'
                accept='video/*'
                onChange={(e) => handleFileChange(e, 'video1')}
              />
              <StyledButton
                onClick={() => handleClick('video1')}
                className='bg-[#000000] mobile:w-[130px] mobile:flex-shrink-0 block mx-auto rounded-none h-[27px] font-bold text-[12px] text-white cursor-pointer'
              >
                ファイルを添付
              </StyledButton>
              {selectedVideo1.isVideo1Picked && (
                <div>
                  <p className='mobile:flex-shrink-0 max-w-[100px] truncate'>
                    {' '}
                    {selectedVideo1.selectedVideo1Item.name}
                    {selectedVideo1.selectedVideo1Item.type}
                  </p>
                </div>
              )}
              <div
                aria-hidden='true'
                className='flex-1 flex justify-end'
                role='button'
                onClick={() => resetFileInput('video1')}
              >
                <DeleteIcon fill={'white'} width={25} height={25} />
              </div>
            </div>
          </div>
          <div>
            <p className='font-bold font-yuGothic text-[16px] mobile:w-[70px]'>動画2</p>
            <div className='flex gap-3 my-3'>
              <input
                ref={video2Ref}
                accept='video/*'
                style={{ display: 'none' }}
                type='file'
                onChange={(e) => handleFileChange(e, 'video2')}
              />
              <StyledButton
                onClick={() => handleClick('video2')}
                className='bg-[#000000] mobile:w-[130px] mobile:flex-shrink-0 block mx-auto rounded-none h-[27px] font-bold text-[12px] text-white cursor-pointer'
              >
                ファイルを添付
              </StyledButton>
              {selectedVideo2.isVideo2Picked && (
                <div>
                  <p className='mobile:flex-shrink-0 max-w-[100px] truncate'>
                    {' '}
                    {selectedVideo2.selectedVideo2Item.name}
                    {selectedVideo2.selectedVideo2Item.type}
                  </p>
                </div>
              )}
              <div
                aria-hidden='true'
                className='flex-1 flex justify-end'
                role='button'
                onClick={() => resetFileInput('video2')}
              >
                <DeleteIcon fill={'white'} width={25} height={25} />
              </div>
            </div>
          </div>
        </Form>
      )}
      <StyledButton
        type='primary'
        htmlType='submit'
        loading={message.loading}
        disabled={
          !selectedFile.selectedFileItem ||
          (!selectedVideo1.selectedVideo1Item && !selectedVideo2.selectedVideo2Item) ||
          message.loading
        }
        onClick={handleUploadFile}
        className='bg-[#000000] mt-12 block mx-auto rounded-none h-[44px] font-bold text-[14px] text-white cursor-pointer'
      >
        レポートを作成
      </StyledButton>
    </div>
  );
};
