// @flow
import * as React from 'react';
import { ForgotPassword } from '../../component/ForgotPassword';

export const ForgotPasswordUser = () => {
  return (
    <>
      <ForgotPassword title={['ログインできない場合は以下の', 'メールアドレスを入力ください']} button={'送信'} />
    </>
  );
};
