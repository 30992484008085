import React from 'react';
import styled from 'styled-components';
import { Alert } from 'antd';

const StyledAlert = ({ message, type }) => {
  const StyledAlert = styled(Alert)`
    .ant-alert-warning {
      border-radius: unset;
    }
  `;
  return (
    <div className='my-[20px] mx-auto max-w-[400px]'>
      <StyledAlert className='bg-[#fcf1ef] border-none h-full' message={message} type={type} showIcon closable />
    </div>
  );
};

export default StyledAlert;
