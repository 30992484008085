export default function getUserName() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  if (localStorage.getItem('nameUser')) {
    let name = JSON.parse(localStorage.getItem('nameUser'));
    return name;
  }
  const encodedName = urlParams.get('username');
  const decodedName = decodeURIComponent(encodedName);
  return decodedName;
}
