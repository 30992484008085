import { CreateReport } from '../pages/createReport/CreateReport';
import { ForgotPasswordUser } from '../pages/forgotPassword/ForgotPasswordUser';
import { AdminLogin } from '../pages/login/AdminLogin';
import { ChangePassword } from '../pages/login/ChangePassword';
import { Login } from '../pages/login/Login';
import { Register } from '../pages/register/Register';
import { ReportDetail } from '../pages/reportDetail/ReportDetail';
import { ResultReportList } from '../pages/resultReportList/ResultReportList';
import { UserDetail } from '../pages/userDetail/UserDetail';
import { UserList } from '../pages/userList/UserList';
import * as URL from '../routes/constantRouter';

export const publicRoutes = [
  { path: URL.LOGIN_USER_URL, component: Login },
  { path: URL.LOGIN_ADMIN_URL, component: AdminLogin },
  { path: URL.REGISTER_URL, component: Register },
  { path: URL.LIST_USER_URL, component: UserList },
  { path: URL.DETAIL_USER_URL, component: UserDetail },
  { path: URL.LIST_REPORT_URL, component: ResultReportList },
  { path: URL.REPORT_DETAIL_URL, component: ReportDetail },
  { path: URL.CREATE_NEW_REPORT_URL, component: CreateReport },
  { path: URL.CHANGE_PASSWORD_URL, component: ChangePassword },
  { path: URL.FORGOT_PASSWORD_URL, component: ForgotPasswordUser },
];
