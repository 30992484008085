// @flow
import { Button, Modal } from 'antd';
import * as React from 'react';
import styled from 'styled-components';
export const MockupMessage = ({ isModalOpen, handleDelete, handleCancel }) => {
  const StyledModal = styled(Modal)`
    .ant-modal-content {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .ant-modal-close {
        display: none;
      }
      .ant-modal-body {
        text-align: center;
      }
    }
  `;
  return (
    <>
      <StyledModal className='!w-[300px] h-[150px]' footer={null} open={isModalOpen}>
        <p className='mb-[20px] font-bold text-[16px] font-yuGothic'>本当に削除しますか?</p>
        <div className='flex gap-10'>
          <Button onClick={handleCancel} className='min-w-[100px] text-[#4096ff] border-[#4096ff]'>
            いいえ
          </Button>
          <Button onClick={handleDelete} className='min-w-[100px] bg-[#FA5882] !text-[white] !border-none '>
            はい
          </Button>
        </div>
      </StyledModal>
    </>
  );
};
