import React, { createContext, useState } from 'react';
import * as UserService from '../../api/apiServices/UserService';
import { Login } from '../../pages/login/Login';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  DETAIL_USER_URL,
  FORGOT_PASSWORD_URL,
  LIST_REPORT_URL,
  LIST_USER_URL,
  LOGIN_ADMIN_URL,
  LOGIN_USER_URL,
  REPORT_DETAIL_URL,
} from '../../routes/constantRouter';
import { ROLE_ADMIN, ROLE_USER } from '../../helper/constant';
import { Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [token, setToken] = useState(() => {
    if (localStorage.getItem('tokens')) {
      let tokens = JSON.parse(localStorage.getItem('tokens'));
      return tokens;
    }
    return null;
  });
  const [typeUSer, setTypeUser] = useState(() => {
    if (localStorage.getItem('role')) {
      let typeUser = JSON.parse(localStorage.getItem('role'));
      return typeUser;
    }
    return null;
  });

  const login = async (email, password) => {
    const apiResponse = await UserService.Login(email, password);
    if (apiResponse?.status) {
      localStorage.setItem('role', JSON.stringify(apiResponse?.data?.role === 'student' ? ROLE_USER : ROLE_ADMIN));
      setTypeUser(apiResponse?.data?.role === 'student' ? ROLE_USER : ROLE_ADMIN);
      localStorage.setItem('nameUser', JSON.stringify(apiResponse?.data?.username));
      localStorage.setItem('tokens', JSON.stringify(apiResponse?.data?.token));
      setToken(apiResponse?.data?.token);
    }
    return apiResponse;
  };
  const [urlDefault, setUrlDefault] = useState('');
  useEffect(() => {
    if (window.location.pathname === REPORT_DETAIL_URL && !token) {
      setUrlDefault(`${window.location.pathname}${window.location.search}`);
    }
  }, []);
  const history = useNavigate();
  useEffect(() => {
    if (!token && window.location.pathname !== LOGIN_ADMIN_URL && window.location.pathname !== FORGOT_PASSWORD_URL) {
      history(LOGIN_USER_URL);
    }
  }, [token]);
  const handleBackWindow = () => {
    if (window.location.pathname === LIST_REPORT_URL || window.location.pathname === LOGIN_ADMIN_URL) {
      history(LOGIN_USER_URL);
    } else if (window.location.pathname === DETAIL_USER_URL) {
      history(LIST_USER_URL);
    } else if (window.location.pathname === LIST_USER_URL) {
      history(LOGIN_ADMIN_URL);
    } else {
      window.history.back();
    }
  };

  return (
    <AuthContext.Provider value={{ token, typeUSer, urlDefault, login }}>
      {token || window.location.pathname === LOGIN_ADMIN_URL || window.location.pathname === FORGOT_PASSWORD_URL ? (
        <>
          {window.location.pathname !== REPORT_DETAIL_URL && window.location.pathname !== LOGIN_USER_URL && (
            <Button
              onClick={() => handleBackWindow()}
              className='flex items-center left-10 top-6 font-semibold hover:!border-slate-400 hover:!text-[black] hover:!opacity-50 transition-none'
            >
              <ArrowLeftOutlined />
              戻る
            </Button>
          )}
          {children}
        </>
      ) : (
        <Login />
      )}
    </AuthContext.Provider>
  );
};

export default AuthContext;
