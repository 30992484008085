// @flow
import * as React from 'react';
import { Form, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import { StyledComponentFrom } from './StyledComponentForm';
import AuthContext from '../layoutt/Authorization/AuthContextProvider';
import StyledAlert from './StyledAlert';
import { StyledButton } from './StyledButton';
import * as Constant from '../helper/constant';
import { FORGOT_PASSWORD_URL, LIST_REPORT_URL, LIST_USER_URL, LOGIN_ADMIN_URL } from '../routes/constantRouter';

export const LoginCp = ({ title, button, roleUser }) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [form] = Form.useForm();
  const { login, urlDefault } = React.useContext(AuthContext);
  const [message, setMessage] = React.useState({ loading: false, message: '' });

  const [visible, setVisible] = React.useState(false);
  const history = useNavigate();

  const onFinish = async () => {
    setMessage((prev) => ({ ...prev, loading: true }));
    const dataLogin = await login(form.getFieldValue('email'), form.getFieldValue('password'));
    setMessage({ message: dataLogin.message, loading: false });
    if (dataLogin?.status) {
      history(roleUser === Constant.ROLE_USER ? (urlDefault ? urlDefault : LIST_REPORT_URL) : LIST_USER_URL);
    } else {
      setVisible(true);
      setMessage((prev) => ({ ...prev, message: dataLogin.message }));
    }
  };
  return (
    <>
      {visible && (
        <StyledAlert type={message.message === 'success' ? 'success' : 'warning'} message={message.message} />
      )}
      <StyledComponentFrom
        onFinish={onFinish}
        form={form}
        className='w-[calc(100%-20px)] h-[410px] mobile:w-full max-w-[400px] mx-auto mt-[67px] mb-auto bg-[#FFFFFF] rounded-lg p-3 mobile:p-9'
      >
        <Form.Item className='text-center font-bold text-[20px] leading-[30px]'>
          <p className='text-[20px] font-bold'> Global Professional</p>
          <p className='text-[20px] font-bold'>{title}</p>
        </Form.Item>
        <Form.Item
          label='メールアドレス'
          name='email'
          className='flex flex-col text-[14px]'
          rules={[{ type: 'email', required: true, message: 'メールアドレス必須' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='パスワード'
          name='password'
          className='flex flex-col text-[14px]'
          rules={[{ required: true, message: 'パスワードが必要!' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item className='mt-10 mb-0 text-center'>
          <StyledButton
            type='primary'
            htmlType='submit'
            loading={message.loading}
            disabled={message.loading}
            className='w-1/2 mt-5 bg-[#000000] rounded-none h-[55px] font-bold text-[14px] text-white mobile:w-full cursor-pointer'
          >
            {button}
          </StyledButton>
        </Form.Item>
      </StyledComponentFrom>
      {roleUser === 'user' && (
        <>
          <a href={LOGIN_ADMIN_URL} className='font-yuGothic text-[#0B3BE6] block text-center mt-[40px] cursor-pointer'>
            管理者のログインはこちらです。
          </a>
          <a
            href={FORGOT_PASSWORD_URL}
            className='font-yuGothic text-center block mt-[40px] text-[#0B3BE6] cursor-pointer'
          >
            ログインできない場合はお問合せください。
          </a>
        </>
      )}
    </>
  );
};
