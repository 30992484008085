// @flow
import * as React from 'react';
import { ChangePasswordCp } from '../../component/ChangePasswordCP';
export const ChangePassword = () => {
  return (
    <>
      <ChangePasswordCp title={'パスワード変更画面'} button={'パスワード変更'} />
    </>
  );
};
