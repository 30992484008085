// @flow
import * as React from 'react';
import { Header } from './Header';
import { Body } from './Body';
export const LayoutDefault = ({ children }) => {
  return (
    <>
      <Header />
      <Body>{children}</Body>
    </>
  );
};
