// @flow
import * as React from 'react';
import { RegisterCp } from '../../component/RegisterCp';
export const Register = () => {
  return (
    <>
      <RegisterCp title={'ユーザー新規登録'} button={'新規登録'} />
    </>
  );
};
