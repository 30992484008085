import { Pagination } from 'antd';
import styled from 'styled-components';

export const StyledPagination = styled(Pagination)`
  .ant-pagination-item-active {
    background-color: black !important;
    border-color: black !important;
    a {
      color: white !important;
    }
  }
  .ant-pagination-options {
    display: none;
  }
`;
