import { Form } from 'antd';
import styled from 'styled-components';

export const StyledComponentFrom = styled(Form)`
  .ant-row {
    display: block;
  }
  .ant-form-item-control-input {
    font-family: 'YuGothic' !important;
    font-style: normal;
  }
  .ant-col {
    label,
    button {
      font-family: 'YuGothic' !important;
      font-style: normal;
    }
  }

  .ant-input,
  .ant-input-affix-wrapper {
    border: 1px solid #d9d9d9;
    border-top: none;
    border-right: none;
    border-left: none;
    box-shadow: none;
    :hover {
      border-color: #d9d9d9;
    }
  }
  .ant-form-item-label > label {
    color: #b7b7b7;
    height: auto;
  }
  .ant-form-item-control-input {
    min-height: unset;
  }
  .ant-btn-primary:not(:disabled):hover {
    background-color: #000000;
  }
  .ant-form-item-feedback-icon {
    display: flex;
  }
  .ant-form-item-required {
    &:before,
    &:after {
      content: '' !important;
    }
  }
  .ant-col {
    .ant-form-item-explain-error {
      margin-bottom: 20px;
    }
  }
`;
