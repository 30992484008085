// @flow
import * as React from 'react';
import * as UserService from '../api/apiServices/UserService';
import { Form, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import { StyledComponentFrom } from './StyledComponentForm';
import StyledAlert from './StyledAlert';
import { StyledButton } from './StyledButton';

export const ChangePasswordCp = ({ title, button }) => {
  const [form] = Form.useForm();
  const history = useNavigate();
  const [message, setMessage] = React.useState({ status: null, message: '', loading: false });

  const handleChangePassword = async () => {
    setMessage({ status: null, message: '', loading: true });
    const result = await UserService.ChangePW(
      form.getFieldValue('oldPassword'),
      form.getFieldValue('newPassword'),
      form.getFieldValue('confirmPassword')
    );
    setMessage({ status: result.status, message: result.message, loading: false });
    if (result.status) {
      localStorage.removeItem('tokens');
      localStorage.removeItem('role');
      setTimeout(() => {
        history('/');
      }, 1400);
    }
  };
  return (
    <>
      {typeof message.status === 'boolean' && (
        <StyledAlert type={message.status ? 'success' : 'warning'} message={message.message} />
      )}
      <StyledComponentFrom
        onFinish={handleChangePassword}
        form={form}
        className='w-[calc(100%-20px)] mobile:w-full max-w-[400px] mx-auto mt-[67px] mb-auto bg-[#FFFFFF] rounded-lg p-3 mobile:p-9'
      >
        <Form.Item className='text-center font-bold text-[20px] leading-[30px]'>
          <p className='text-[20px] font-bold'> Global Professional</p>
          <p className='text-[20px] font-bold'>{title}</p>
        </Form.Item>
        <Form.Item
          label='以前のパスワード'
          name='oldPassword'
          className='flex flex-col text-[14px]'
          rules={[
            {
              required: true,
              message: 'パスワードが必要!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='新しいパスワード'
          name='newPassword'
          className='flex flex-col text-[14px]'
          rules={[
            {
              required: true,
              message: 'パスワードを入力してください!',
            },
          ]}
          hasFeedback
        >
          <Input.Password minLength='8' />
        </Form.Item>
        <Form.Item
          label='新しいパスワード（確認)'
          name='confirmPassword'
          className='flex flex-col text-[14px]'
          rules={[
            {
              required: true,
              message: 'パスワードを確認してください!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(''));
              },
            }),
          ]}
          dependencies={['newPassword']}
          hasFeedback
        >
          <Input.Password minLength='8' />
        </Form.Item>

        <Form.Item className='mt-10 mb-0 text-center'>
          <StyledButton
            type='primary'
            htmlType='submit'
            disabled={message.loading}
            loading={message.loading}
            className='w-1/2 bg-[#000000] rounded-none h-[55px] font-bold text-[14px] text-white mobile:w-full cursor-pointer'
          >
            {button}
          </StyledButton>
        </Form.Item>
      </StyledComponentFrom>
    </>
  );
};
