// Teacher
export const URL_API_LOGIN = 'teacher/login';
export const URL_API_GET_LIST_USER = 'teacher/users';
export const URL_API_CREATE_NEW_USER = 'teacher/users';
export const URL_API_GET_DATA_DETAIL_USER = 'teacher/users';
export const URL_API_GET_DATA_REPORT_DETAIL_TEACHER = 'teacher/users';
export const URL_API_CREATED_NEW_REPORT = 'teacher/users';
export const URL_API_DELETE_REPORT = 'teacher/users';
export const URL_API_ADMIN_DOWNLOAD_PDF = 'teacher/download';

//Student
export const URL_API_STUDENT = 'student/login';
export const URL_API_GET_REPORT_USER = 'student/reports';
export const URL_API_GET_DATA_REPORT_DETAIL_STUDENT = 'student/reports';
export const URL_API_FORGOT_PASSWORD = 'student/forgot_password';
export const URL_API_USER_DOWNLOAD_PDF = 'student/download';
export const URL_API_CHANGE_PASSWORD = 'student/change_password';
