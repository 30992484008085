/* eslint-disable no-unused-vars */
import { ROLE_ADMIN } from '../../helper/constant';
import { LOGIN_ADMIN_URL } from '../../routes/constantRouter';
import Axios from '../Axios';
import * as ApiUser from './constant';

//Teacher and User
export const Login = async (email, password) => {
  const LogInType = window.location.pathname === LOGIN_ADMIN_URL ? ApiUser.URL_API_LOGIN : ApiUser.URL_API_STUDENT;
  try {
    const data = await Axios.post(LogInType, {
      email: email,
      password: password,
    });
    return data;
  } catch (error) {
    return error.response.data;
  }
};
export const getDataReportDetail = async (reportId, userId) => {
  const roleUser = JSON.parse(localStorage.getItem('role'));
  try {
    let data = null;
    if (roleUser === ROLE_ADMIN) {
      data = await Axios.get(`${ApiUser.URL_API_GET_DATA_REPORT_DETAIL_TEACHER}/${userId}/reports/${reportId}`, {});
    } else {
      data = await Axios.get(`${ApiUser.URL_API_GET_DATA_REPORT_DETAIL_STUDENT}/${reportId}`, {});
    }
    return data;
  } catch (error) {
    return error.response.data;
  }
};
export const downLoadFilePDF = async (id) => {
  const roleUser = JSON.parse(localStorage.getItem('role'));
  const url = roleUser === ROLE_ADMIN ? ApiUser.URL_API_ADMIN_DOWNLOAD_PDF : ApiUser.URL_API_USER_DOWNLOAD_PDF;
  try {
    const data = await Axios.get(`${url}/${id}`, {
      headers: { Accept: 'application/json' },
      responseType: 'arraybuffer',
    });
    return data;
  } catch (error) {
    return error.response.data;
  }
};
// Teacher
export const getListDataUser = async (page = 1, search = '', per = 10) => {
  try {
    const data = await Axios.get(`${ApiUser.URL_API_GET_LIST_USER}?page=${page}&search=${search}&per=${per}`, {});
    return data;
  } catch (error) {
    return error.response.data;
  }
};

export const createNewUser = async (username, email) => {
  try {
    const data = await Axios.post(ApiUser.URL_API_CREATE_NEW_USER, {
      username,
      email,
    });
    return data;
  } catch (error) {
    return error.response.data;
  }
};

export const CreateNewReport = async (payload, userId) => {
  try {
    const data = await Axios.post(`${ApiUser.URL_API_CREATED_NEW_REPORT}/${userId}/reports`, payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return data;
  } catch (error) {
    return error.response.data;
  }
};

export const getDataUserDetail = async (id, page) => {
  try {
    const data = await Axios.get(`${ApiUser.URL_API_GET_DATA_DETAIL_USER}/${id}/reports?page=${page}`, {});
    return data;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteReport = async (userId, reportId) => {
  try {
    const data = await Axios.delete(`${ApiUser.URL_API_DELETE_REPORT}/${userId}/reports/${reportId}`, {});
    return data;
  } catch (error) {
    return error.response.data;
  }
};

// Student
export const ChangePW = async (current_password, password, password_confirmation) => {
  try {
    const data = await Axios.post(ApiUser.URL_API_CHANGE_PASSWORD, {
      current_password,
      password,
      password_confirmation,
    });
    return data;
  } catch (error) {
    return error.response.data;
  }
};

export const getListReportForUser = async () => {
  try {
    const data = await Axios.get(ApiUser.URL_API_GET_REPORT_USER, {});
    return data;
  } catch (error) {
    return error.response.data;
  }
};

export const forgotPassword = async (email) => {
  try {
    const data = await Axios.post(ApiUser.URL_API_FORGOT_PASSWORD, { email });
    return data;
  } catch (error) {
    return error.response.data;
  }
};
