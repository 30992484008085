// @flow
import { Button, Checkbox, Table } from 'antd';
import * as React from 'react';
import styled from 'styled-components';
import { DeleteIcon } from '../Icons/DeleteIcon';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import * as UserService from '../api/apiServices/UserService';
import { StyledButton } from './StyledButton';
import getUserName from '../helper/GetUserName';
import { MockupMessage } from '../helper/MockupMessage';
import { StyledPagination } from './StyledPagination';
import { DEFAULT_PAGE_SIZE } from '../helper/constant';
import { PrevIcon } from '../Icons/PrevIcon';
import { NextIcon } from '../Icons/NextIcon';

export const UserDetailCp = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const TableExpand = styled(Table)`
    .ant-table {
      margin-top: 25px;
      border-radius: 8px;
      min-height: 300px;
      @media (max-width: 570px) {
        overflow-x: scroll;
     };
      }
    }
    .ant-table-header > table > thead > tr > th {
      border: 1px solid rgba(209, 242, 183, 255);
    }
    .ant-table-thead > tr {
      .ant-table-cell {
        background-color: rgb(217, 217, 217);
        word-break: keep-all;
      }
      >td {
        &:before {
          content: unset !important;
        }
      }
      > th {
        background-color: #d9d9d9;
        color: #000000;
        text-align: center;
        padding: 11px;
        &:before {
          content: unset !important;
        }
      }
    }
    .ant-table-tbody > tr {
      background: #fafafa;
      > td {
        text-align: center;
        padding: 14px;
      }
    }
    .ant-pagination {
      display: none;
    }
  `;
  const StyledCheckbox = styled(Checkbox)`
    .ant-checkbox-input {
      cursor: no-drop;
    }
  `;
  const [detailUSer, setDetailUser] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [loadingDeleteReport, setLoadingDeleteReport] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pagination, setPagination] = React.useState(null);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const idUser = urlParams.get('id');
  const [nameUser] = React.useState(getUserName());

  const getDataUSer = async () => {
    const dataUser = await UserService.getDataUserDetail(idUser, currentPage);
    setPagination(dataUser?.pagination);
    const dataDetail = dataUser.data.map((item) => ({
      ...item,
      url: `${window.location.origin}/report-detail?userId=${idUser}&reportId=${item.id}`,
    }));
    setLoading(false);
    setDetailUser(dataDetail);
  };
  useEffect(() => {
    getDataUSer();
  }, [currentPage]);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [reportId, setReportId] = React.useState(null);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleDeleteReport = async () => {
    handleCancel(false);
    setLoadingDeleteReport(true);
    const deleteData = await UserService.deleteReport(idUser, reportId);
    setLoadingDeleteReport(false);
    if (deleteData?.status) {
      getDataUSer();
    }
  };
  const itemRender = (_, type, originalElement) => {
    if (type === 'prev') {
      return <PrevIcon fill='none' />;
    }
    if (type === 'next') {
      return <NextIcon fill='none' />;
    }
    return originalElement;
  };
  const columns = [
    {
      title: '作成日時',
      dataIndex: 'created_at',
      key: 'created_at',
    },
    {
      title: 'レポートURL',
      dataIndex: 'url',
      key: 'url',
      render: (url) => (
        <p className='text-[#000AFF] cursor-pointer'>
          <a href={url}>
            <u>{url}</u>
          </a>
        </p>
      ),
    },
    {
      title: ' 送信済み ',
      dataIndex: '',
      width: 100,
      key: 'checkbox',
      render: () => <StyledCheckbox checked />,
    },
    {
      title: '',
      dataIndex: '',
      key: 'action',
      render: (item) => (
        <Button
          disabled={loadingDeleteReport}
          onClick={() => {
            setReportId(item.id);
            showModal();
          }}
          className='cursor-pointer border-none'
          key={item.id}
        >
          <DeleteIcon key={item.id} fill='white' width={20} height={20} />
        </Button>
      ),
    },
  ];
  const history = useNavigate();
  const handleCreateNewReport = () => {
    history(`/create-new-report?userId=${idUser}&username=${nameUser}`);
  };

  return (
    <>
      <MockupMessage isModalOpen={isModalOpen} handleDelete={handleDeleteReport} handleCancel={handleCancel} />
      <div className='w-[calc(100%-20px)] h-full mobile:w-[calc(100%-50px)] tablet:w-full max-w-[800px] mx-auto mt-[33px] mb-auto'>
        <div className='mobile:flex block justify-between items-center h-auto mobile:h-[43px]'>
          <div className='flex w-full gap-8 items-center justify-between mt-4 mobile:my-0'>
            <div className='font-bold font-yuGothic text-[20px]'>{nameUser}さん</div>
            <StyledButton
              type='primary'
              htmlType='submit'
              onClick={handleCreateNewReport}
              className='bg-[#000000] rounded-none font-bold text-[14px] text-white cursor-pointer h-[44px]'
            >
              新規レポート作成
            </StyledButton>
          </div>
        </div>
        <TableExpand
          className='mb-[100px]'
          columns={columns}
          dataSource={detailUSer}
          footer={() => ''}
          loading={loading}
        />
      </div>
      <StyledPagination
        className='flex gap-4 mt-5 justify-center'
        total={pagination?.total_record}
        defaultCurrent={1}
        current={currentPage}
        itemRender={itemRender}
        pageSize={DEFAULT_PAGE_SIZE}
        onChange={(e) => setCurrentPage(e)}
      />
    </>
  );
};
