// @flow
import * as React from 'react';
import { Form, Input } from 'antd';
import { StyledComponentFrom } from './StyledComponentForm';
import * as UserService from '../api/apiServices/UserService';
import StyledAlert from './StyledAlert';
import { StyledButton } from './StyledButton';
import { useNavigate } from 'react-router-dom';
import { LIST_USER_URL } from '../routes/constantRouter';

export const RegisterCp = ({ title, button }) => {
  const [form] = Form.useForm();
  const history = useNavigate();
  const [message, setMessage] = React.useState({ status: null, message: '', loading: false });
  const CreateNewUser = async () => {
    setMessage((prev) => ({ ...prev, loading: true, status: null, message: '' }));
    const newUser = await UserService.createNewUser(form.getFieldValue('username'), form.getFieldValue('email'));
    setMessage({
      status: newUser.status,
      message: newUser.status ? 'アカウントが正常に作成されました' : 'メールアドレスは存在しています。',
      loading: false,
    });
    if (newUser.status) {
      setTimeout(() => {
        history(LIST_USER_URL);
      }, 1200);
    }
  };
  return (
    <>
      {typeof message.status === 'boolean' && (
        <StyledAlert type={message.status ? 'success' : 'warning'} message={message.message} />
      )}
      <StyledComponentFrom
        onFinish={CreateNewUser}
        form={form}
        className='w-[calc(100%-20px)] h-[410px] mobile:w-full max-w-[400px] mx-auto mt-[67px] mb-auto bg-[#FFFFFF] rounded-lg p-3 mobile:p-9'
      >
        <Form.Item className='text-center font-bold text-[20px] leading-[30px]'>
          <p className='text-[20px] font-bold'> Global Professional</p>
          <p className='text-[20px] font-bold'>{title}</p>
        </Form.Item>
        <Form.Item
          label='お名前'
          name='username'
          className='flex flex-col text-[14px]'
          rules={[
            {
              required: true,
              message: 'ユーザー名を入力してください!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <div className='text-[10px] mb-[10px] mt-[-10px] font-bold'>※姓名の間はスペースなしで入力してください。</div>
        <Form.Item
          label='メールアドレス'
          name='email'
          className='flex flex-col text-[14px]'
          rules={[
            {
              required: true,
              type: 'email',
              message: 'メールアドレスを入力してください!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item className='mt-10 mb-0 text-center'>
          <StyledButton
            type='primary'
            htmlType='submit'
            disabled={message.loading}
            loading={message.loading}
            className='w-1/2 mt-5 bg-[#000000] rounded-none h-[55px] font-bold text-[14px] text-white mobile:w-full cursor-pointer'
          >
            {button}
          </StyledButton>
        </Form.Item>
      </StyledComponentFrom>
    </>
  );
};
