import axios from 'axios';
import queryString from 'query-string';
import { set } from 'lodash';

const Axios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  paramsSerializer: (params) => queryString.stringify(params),
});
Axios.interceptors.request.use((request) => {
  let tokensData = JSON.parse(localStorage.getItem('tokens'));
  if (!tokensData) {
    return request;
  }
  set(request, 'headers.Authorization', `Bearer ${tokensData}`);
  return request;
});
Axios.interceptors.response.use(
  (response) => {
    if (response && response.data) {
      return response.data;
    }
    return response;
  },
  async (error) => {
    const errorResponse = error.response;
    if (errorResponse && errorResponse.status === 401) {
      localStorage.clear();
      window.location.replace('/');
    }
    Promise.reject(error);
    throw error;
  }
);

export default Axios;
