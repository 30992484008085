// USER_ADMIN
export const REPORT_DETAIL_URL = '/report-detail';
//USER(STUDENT)
export const LOGIN_USER_URL = '/';
export const LIST_REPORT_URL = '/report-list';
export const CHANGE_PASSWORD_URL = '/change-password';
export const FORGOT_PASSWORD_URL = '/forgot-password';

//Admin(teacher)
export const DETAIL_USER_URL = '/detail-user';
export const LOGIN_ADMIN_URL = '/login-admin';
export const REGISTER_URL = '/register';
export const LIST_USER_URL = '/list-user';
export const CREATE_NEW_REPORT_URL = '/create-new-report';
