// @flow
import React from 'react';
export const SearchIcon = ({ fill, width, height }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 20 20' fill={fill} xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M19 19L14.65 14.65M9 4C11.7614 4 14 6.23858 14 9M17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9Z'
        stroke='black'
        strokeOpacity='0.75'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
