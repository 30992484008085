// @flow
import { Input, Table } from 'antd';
import * as React from 'react';
import styled from 'styled-components';
import { SearchIcon } from '../Icons/SearchIcon';
import { Link, useNavigate } from 'react-router-dom';
import { PrevIcon } from '../Icons/PrevIcon';
import { NextIcon } from '../Icons/NextIcon';
import * as UserService from '../api/apiServices/UserService';
import { StyledButton } from './StyledButton';
import { DEFAULT_PAGE_SIZE } from '../helper/constant';
import { DETAIL_USER_URL } from '../routes/constantRouter';
import { StyledPagination } from './StyledPagination';

export const ListUser = () => {
  const columns = [
    {
      title: '名前',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'メールアドレス',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: '登録日',
      dataIndex: 'created_at',
      key: 'created_at',
    },
  ];

  const TableExpand = styled(Table)`
    .ant-table {
      margin-top: 25px;
      border-radius: 8px;
      min-height: 300px;
      @media (max-width: 570px) {
        overflow-x: scroll;
      }
    }
    .ant-table-header > table > thead > tr > th {
      border: 1px solid rgba(209, 242, 183, 255);
    }
    .ant-table-thead > tr > th {
      background-color: #d9d9d9;
      color: #000000;
      padding: 11px;
      width: 33.33%;
      text-align: center;
      &:before {
        content: unset !important;
      }
    }
    .ant-table-tbody > tr {
      :hover {
        background: #dcf8fe !important;
        .ant-table-cell {
          background: #dcf8fe !important;
        }
      }
      > td {
        padding: 14px;
        cursor: pointer;
        width: 33.33%;
        text-align: center;
      }
    }
    .ant-pagination {
      display: none;
    }
  `;

  const history = useNavigate();
  const itemRender = (_, type, originalElement) => {
    if (type === 'prev') {
      return <PrevIcon fill='none' />;
    }
    if (type === 'next') {
      return <NextIcon fill='none' />;
    }
    return originalElement;
  };

  const { Search } = Input;
  const StyledInput = styled(Search)`
    .ant-input-group-addon {
      display: none;
    }
    .ant-input-wrapper {
      height: 100%;
      width: 100%;
      .ant-input-affix-wrapper {
        border-radius: unset !important;
        height: 100%;
        width: 100%;
        border-color: #d9d9d9 !important;
        box-shadow: unset;
        .ant-input-clear-icon {
          display: flex;
        }
      }
    }
  `;

  const [currentPage, setCurrentPage] = React.useState(1);
  const onHandleShowData = (current) => {
    setCurrentPage(current);
  };
  const [listUser, SetListUser] = React.useState();
  const [inputSearch, setInputSearch] = React.useState('');
  const [loading, setLoading] = React.useState(true);
  const emailInput = React.useCallback((inputElement) => {
    if (inputElement && inputElement.input.defaultValue !== '') {
      inputElement.focus();
    }
  }, []);
  const onChangeInput = (e) => {
    setInputSearch(e.target.value);
  };
  const getListUser = async () => {
    const lisData = await UserService.getListDataUser(currentPage, inputSearch, DEFAULT_PAGE_SIZE);
    setLoading(false);
    if (lisData.status) {
      SetListUser(lisData.data);
    }
  };
  React.useEffect(() => {
    getListUser();
    window.scrollTo(0, 0);
  }, [currentPage]);

  return (
    <div className='w-[calc(100%-20px)] mobile:w-[calc(100%-50px)] tablet:w-full max-w-[800px] mx-auto mt-[33px] mb-auto'>
      <div className='mobile:flex block justify-between items-center h-auto mobile:h-[43px]'>
        <div className='font-bold text-[20px] font-yuGothic'>ユーザー一覧</div>
        <div className='flex gap-8 justify-between mt-4 mobile:my-0'>
          <StyledInput
            onSearch={getListUser}
            ref={emailInput}
            value={inputSearch}
            onChange={(e) => {
              e.preventDefault();
              onChangeInput(e);
            }}
            placeholder='名前で検索'
            className='h-[44px] rounded-none hover:shadow-none max-w-[200px]'
            suffix={
              <div role='button' aria-hidden='true' onClick={getListUser}>
                <SearchIcon width={20} height={20} fill={'none'} />
              </div>
            }
          />
          <Link to='/register'>
            <StyledButton
              type='primary'
              htmlType='submit'
              className='bg-[#000000] rounded-none font-bold text-[14px] text-white cursor-pointer h-[44px]'
            >
              ユーザー新規登録
            </StyledButton>
          </Link>
        </div>
      </div>
      <TableExpand
        loading={loading}
        columns={columns}
        dataSource={listUser?.students}
        footer={() => ''}
        onRow={(record) => {
          return {
            onClick: (e) => {
              e.preventDefault();
              localStorage.setItem('nameUser', JSON.stringify(record.name));
              history(`${DETAIL_USER_URL}?id=${record.id}&username=${record.name}`);
            },
          };
        }}
      />
      <StyledPagination
        className='flex gap-4 mt-6 justify-center'
        total={listUser?.total_items}
        defaultCurrent={1}
        current={currentPage}
        itemRender={itemRender}
        pageSize={DEFAULT_PAGE_SIZE}
        onChange={onHandleShowData}
      />
      ;
    </div>
  );
};
